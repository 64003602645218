import React from 'react'
import Helmet from 'react-helmet'

import Page from '../components/Page'
import FullPageHeading from '../components/FullPageHeading'

const NotFound = () => {
  return (
    <Page>
      <Helmet title='Page not found' />
      <FullPageHeading
        headlines={['Page', 'not found']}
        text={<a href='/'>Back to home</a>}
      />
    </Page>
  )
}

export default NotFound
