import React from 'react'

import Text from '../Text'

const FullPageHeading = ({
  headlines,
  text
}) => {
  return (
    <div className='fb-full-page-heading'>
      <div className='fb-full-page-heading__content'>
        {headlines && (
          <h1 className='fb-full-page-heading__heading'>
            {headlines.map(line => (
              <Text.TextureHeading tag='div' color='white' key={line}>
                {line}
              </Text.TextureHeading>
            ))}
          </h1>
        )}
        {text && (
          <Text>
            {text}
          </Text>
        )}
      </div>
    </div>
  )
}

export default FullPageHeading
